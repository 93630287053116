import { Dropdown, Menu } from 'antd';
import { ContainerState } from 'containers/MainLayout/types';
import React, { FC, useMemo } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Category } from 'types/schema';
import getSelectedCategory from 'utils/getSelectedCategory';
import { parse } from 'utils/queryString';
import { ErrorBoundary } from '../CategoryNav/ErrorBoundary';
import SVGIcon from '../SVGIcon/SVGIcon';
import ArrowDown from './ArrowDown';
import { ALL_PRODUCTS, tags } from './sidebarFilterData';
import { createSearchQueryfromSlug, getPathSegmentFromSlug } from 'utils/getPathSegmentFromSlug';
import { generateSlugFromID } from 'utils/urls/categoriesPathSegment';

const TopNavMenuItem = styled(Dropdown)<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 10pt;
  height: 100%;
  width: 100%;
  &.root-class {
    left: 0 !important;
  }
  @media (max-width: 767px) {
    padding: 16px 0 6px 0;
  }
`;

const MenuLabel = styled.div`
  flex: 1;
  font-size: 14px;
`;

const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  flex-wrap: nowrap;
  padding: 8pt;
  margin: 0 !important;
  width: 100%;
  ${MenuLabel} {
    color: ${({ color }) => color};
  }
  ${SVGIcon} + ${MenuLabel} {
    margin-left: 10pt;
  }
`;
const MenuItem = styled(Menu.Item)`
  &.selected {
    a div {
      color: #43a047;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
  }
`;
const CategoryNameWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) and (min-width: 768px) {
    padding: 26px 0px;
  }
`;
const CategoryName = styled.div`
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 767px) {
    color: #ffffff;
  }
`;
interface CategoryNavProps extends RouteComponentProps {
  main?: ContainerState;
}
const MainCategoryMobile: FC<CategoryNavProps> = (props) => {
  const { main, location } = props;
  const lang = localStorage.getItem('lang') || 'vi';
  const path = location.pathname === '/favorite' ? '/favorite' : '/market';
  const segment = getPathSegmentFromSlug(location);
  const searchQuery = createSearchQueryfromSlug(location, segment);
  const search = parse(searchQuery);
  const categories = useMemo(() => {
    return window.location.href.indexOf('favorite') > -1 ? main?.favoriteCategories : (main?.categories ?? []);
  }, [main, location]);
  const selectedCategory = useMemo(() => {
    const substate = main;
    return getSelectedCategory(searchQuery, substate && substate.categories);
  }, [searchQuery, main]);
  const categoryMenu = useMemo(
    () => (
      <Menu>
        <MenuItem
          key={ALL_PRODUCTS}
          className={
            location.pathname !== '/' && !selectedCategory && searchQuery.indexOf('tags') === -1 ? 'selected' : ''
          }
        >
          <MenuItemLink to={path}>
            <MenuLabel>{lang === 'en' ? ALL_PRODUCTS : 'Tất cả'}</MenuLabel>
          </MenuItemLink>
        </MenuItem>
        {/* {tags.map((tag) => {
          return (
            <MenuItem key={tag.name} className={searchQuery.search(tag.value) !== -1 ? 'selected' : ''}>
              <MenuItemLink to={path === '/favorite' ? `${path}?tags=${tag.value}` : `/${tag.value}`}>
                <MenuLabel>{tag.nameLocal}</MenuLabel>
              </MenuItemLink>
            </MenuItem>
          );
        })} */}
        {categories.map((category: Category) => {
          return (
            <MenuItem
              key={category.name}
              className={selectedCategory && selectedCategory.name.indexOf(category.name) > -1 ? 'selected' : ''}
            >
              <MenuItemLink
                to={
                  path === '/favorite'
                    ? `${path}?category=${encodeURIComponent(category.name)}`
                    : `/${generateSlugFromID(category, lang)}`
                }
              >
                <MenuLabel>{category.nameLocal}</MenuLabel>
              </MenuItemLink>
            </MenuItem>
          );
        })}
      </Menu>
    ),
    [categories, selectedCategory, location.search],
  );
  const categoryName = useMemo(() => {
    if (!selectedCategory) {
      if (search?.filter?.tags?.includes('SUPER_COMBO')) {
        return 'Combo';
      }
      return search?.filter?.tags
        ? lang === 'en'
          ? 'Category'
          : 'Danh mục sản phẩm'
        : lang === 'en'
          ? ALL_PRODUCTS
          : 'Tất cả';
    }
    if (selectedCategory && 'parent' in selectedCategory) {
      return selectedCategory?.parent?.nameLocal;
    }
    return selectedCategory?.nameLocal;
  }, [location, selectedCategory]);

  return (
    <ErrorBoundary>
      <TopNavMenuItem
        id="TopNavMenuItem"
        overlay={categoryMenu}
        trigger={['click']}
        placement="bottom"
        overlayClassName="root-class"
      >
        <CategoryNameWrapper>
          <CategoryName>{categoryName}</CategoryName>
          <ArrowDown />
        </CategoryNameWrapper>
      </TopNavMenuItem>
    </ErrorBoundary>
  );
};

export default withRouter(MainCategoryMobile);
